/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import 'bootstrap/dist/css/bootstrap.min.css';
import "./src/styles/scss/bootstrap-overrides.scss";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import "./src/styles/global.css";

// gatsby-browser.js
export const onInitialClientRender = () => {
	const userPreferredLang = localStorage.getItem('preferredLanguage') || 'es';

	if (window.location.pathname === '/') {
	  window.location.replace(`/${userPreferredLang}`);
	}
  };